import { Typography, Box, Button, Grid } from '@mui/material'
import { Container } from '@mui/material';
import { useState, useEffect } from 'react'
import Historico from '../Historico/historico'
import Crear from '../Crear/crear'
import LoadingSpinner from "../Loading/LoadingSpinner";
import { useTranslation } from 'react-i18next';

function Work(prop) {
  const [menuActive, setMenuActive] = useState("")
  const [historial, setHistorial] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [showCrear, setShowCrear] = useState(false);
  const [showHistorico, setShowHistorico] = useState(true);


  const { i18n, t } = useTranslation();
  const [imageOutputVariation, setImageOutputVariation] = useState([])
  const [imageOutputVariationHistoric, setImageOutputVariationHistoric] = useState(null)

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!localStorage.getItem('userToken'))
      setShowHistorico(false)

    if (prop.images) {
      if (prop.images.length != imageOutputVariation.length) {
        setImageOutputVariation(prop.images)
        setShowCrear(true)
        setMenuActive("crear")
      }
    } else {
      if (!historial) {
        handleHistorico()
      }
    }
  }, [prop.images]);

  const handleHistorico = () => {
    setIsLoading(true)
    fetch(apiUrl + '/api/historial', {
      method: 'get',
      headers: {
        'token': 'Bearer ' + localStorage.getItem('userToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        setImageOutputVariationHistoric([])
        setHistorial(data);
        setMenuActive('historico');
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error:', error)
        setIsLoading(false)
      });
  }

  const callVariacion = (formData) => {
    prop.setLoadingValue(10)
    handleVariacion(formData, 0, prop.renderId)
  }

  const callVariacionHistorico = (formData, renderId) => {
    prop.setLoadingValue(10)
    handleVariacion(formData, 0, renderId)
  }

  const handlerLoading = (value) => {
    setIsLoading(value)
  }

  const handleVariacion = (formData, variacion, renderId) => {
    let generar = "generar"
    if (!localStorage.getItem('userToken')) {
      generar = "generar_free"
    }
    fetch(apiUrl + '/api/' + generar, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      },
      body: JSON.stringify({
        "generation_id": renderId,
        "style": formData.estilo,
        "roomType": formData.tipo
      })
    })
      .then(response => response.json())
      .then(data => {
        obtenerRespuesta(data.generation_id, formData, variacion, renderId);
      })
      .catch(error => {
        console.error('Error:', error)
        prop.setLoadingValue(0)
      });
  }

  const obtenerRespuesta = (generation_id, formData, variacion, renderId) => {
    fetch(apiUrl + '/api/status/' + generation_id, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        // 'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == 'rendering' || data.status == 'queued') {
          if (data.progress === 0 && variacion === 0) {
            prop.setLoadingValue(loadingValue => {
              const nextValue = loadingValue + randomIncrement(50, loadingValue);
              return nextValue;
            });
          } else if (data.progress !== 0 && variacion === 0) {
            prop.setLoadingValue(prevValue => {
              const nextValue = prevValue + randomIncrement(100, prevValue);
              return nextValue;
            });
          }
          syncWait(500)
          obtenerRespuesta(generation_id, formData, variacion, renderId);
          return;
        }
        if (data.status == 'error') {
          handleVariacion(generation_id, formData, variacion)
        }
        prop.setLoadingValue(0)
        if (variacion < 1) {
          data.outputs = data.outputs.reverse()
          setImageOutputVariation(data.outputs)
          setImageOutputVariationHistoric(data.outputs)
          syncWait(500)

          handleVariacion(formData, variacion + 1, renderId)
        } else {
          // syncWait(50000)
          data.outputs = data.outputs.reverse()
          setImageOutputVariation(data.outputs)
          setImageOutputVariationHistoric(data.outputs)
        }
        // setMenuActive("historico")
      })
      .catch(error => {
        console.error('Error:', error)
      });
  }

  function randomIncrement(target, currentValue) {
    const maxLimit = target === 50 ? 49.9 : 99.9; // Limites de falso incremento
    const maxIncrement = maxLimit - currentValue; // Máximo que puede incrementar
    const increment = Math.random() * (maxIncrement / 5); // Genera un valor aleatorio

    return increment > maxIncrement ? maxIncrement : increment; // Controla el límite superior
  }

  const syncWait = (ms) => {
    const end = Date.now() + ms
    while (Date.now() < end) continue
  }

  return (
    <div class="work-div" style={{ height: '100%' }}>
      <Container maxWidth={false} sx={{
        borderBottom: '1px solid #3a3845', height: '60px', background: '#1F1D2B', paddingTop: '29px!important',
        paddingLeft: '0px!important'
      }}>
        <Box sx={{ display: 'flex' }}>
          {/* <TuneIcon></TuneIcon> */}
          {showCrear && (<Typography className={"menu " + (menuActive == 'crear' ? 'menuActive' : 'hidden')} component="span" onClick={() => setMenuActive('crear')}
            sx={{ fontFamily: 'Barlow', fontWeight: '600', fontSize: '14px' }}>{t("image_edit")}</Typography>)}
          {showHistorico && (<Typography className={"menu " + (menuActive == 'historico' ? 'menuActive' : 'hidden')} component="span" onClick={() => handleHistorico()}
            sx={{ marginLeft: '10px', fontFamily: 'Barlow', fontWeight: '600', fontSize: '14px' }}>{t("historico")}</Typography>)}
        </Box>
      </Container>

      <Box sx={{ justifyContent: 'center', height: '100%' }}>
        {(menuActive == 'crear') &&
          <Crear imageOutputVariation={imageOutputVariation} handleVariacion={callVariacion} volverFormulario={prop.volverFormulario}></Crear>
        }
        {(menuActive == 'historico') &&
          <Historico historial={historial} handleVariacion={callVariacionHistorico} imageOutputVariation={imageOutputVariationHistoric} handlerLoading={handlerLoading} />
        }
      </Box>
      {isLoading && (
        <div >
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default Work