import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import './modal.css';
import { useState, useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const style = {
    background: '#252836',
    padding: '0px'
};

export default function ModalMercadoPago(prop) {
    const [imageModal, setImageModal] = useState(null);

    useEffect(() => {
        if (prop.imageModal) {
            setImageModal(prop.imageModal)
        }
    }, [prop.imageModal]);
    return (
        <Dialog
            fullWidth={true}
            open={prop.open}
            onClose={prop.handleModalClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth={'lg'}
            PaperProps={{ sx: { borderRadius: "10px 10px 10px 10px", padding: '0px',     maxWidth: '100%',
                maxHeight: '90vh',
                width: 'auto',
                height: 'auto'} }}
        >
            <DialogContent style={style}>
                <HighlightOffIcon onClick={() => prop.handleModalClose()} sx={{position:'absolute', top:'30px', right:'30px', fontSize:"50px", cursor:'pointer'}}></HighlightOffIcon>
                <img class="dialog-img" src={imageModal} alt="Imagen" crossOrigin="anonymous" />
            </DialogContent>
        </Dialog>
    );
}