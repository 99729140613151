import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import './modal.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { initMercadoPago } from "@mercadopago/sdk-react";
import MercadoPago from "../MercadoPago/MercadoPago";
import { useState, useEffect } from 'react'
import { Typography, Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next';

const style = {
    background: '#252836',
};

// initMercadoPago("TEST-69260a56-57aa-4e0b-b938-7662437debb6", { locale: 'es-AR' });
// initMercadoPago("APP_USR-cd32fe32-f29f-46a6-9e99-3b252f83bf18", { locale: 'es-AR' });
// initMercadoPago("APP_USR-7a8d44be-9566-4908-bb37-a59946f8b099", { locale: 'es-AR' });
initMercadoPago("APP_USR-9c8fd33a-3311-4dc6-bde7-1dc2e2c13e5a", { locale: 'es-AR' });

export default function ModalPago(prop) {
    const { i18n, t } = useTranslation();

    return (
        <div>
            <Dialog
                open={prop.open}
                onClose={prop.handleModalClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
                PaperProps={{ sx: { borderRadius: "10px 10px 10px 10px" } }}
            >
                <DialogContent style={style}>
                    <HighlightOffIcon onClick={() => prop.handleModalClose()} sx={{ position: 'absolute', top: '5px', right: '5px', fontSize: "30px", cursor: 'pointer' }}></HighlightOffIcon>
                    <div class="columns">
                        <ul class="price">
                            <li class="header">Básico</li>
                            <li class="grey">$ 1.999</li>
                            <li>1 habitación</li>
                            <li>15 variaciones por habitacion</li>
                            <li>6 Tipos de habitación</li>
                            <li>7 estilos</li>
                            <li class="grey"><a href="#" class="button box-options-mp" onClick={(e) => { prop.comprar(1, 1999) }}>
                                <img class="imageMP" src="./mercadopago.png" alt="clientes"></img>

                                <Typography className={'text'} component="h2" >
                                {t("comprar")}
                                </Typography>
                            </a>
                            </li>
                        </ul>
                    </div>
                    <div class="columns">
                        <ul class="price">
                            <li class="header">Medio</li>
                            <li class="grey">$ 9.499</li>
                            <li>5 habitaciones</li>
                            <li>Beneficios planes anteriores</li>
                            <li>$ 1.899 por Imagen</li>
                            <li>Contacto personalizado</li>
                            <li class="grey"><a href="#" class="button box-options-mp" onClick={(e) => { prop.comprar(5, 1899) }}>
                                <img class="imageMP" src="./mercadopago.png" alt="clientes"></img>

                                <Typography className={'text'} component="h2" >
                                {t("comprar")}
                                </Typography>
                            </a>
                            </li>
                        </ul>
                    </div>
                    <div class="columns">
                        <ul class="price">
                            <li class="header">Avanzado</li>
                            <li class="grey">$ 17.990 </li>
                            <li>10 habitaciones</li>
                            <li>Beneficios planes anteriores</li>
                            <li>$ 1.799 por Imagen</li>
                            <li>Contacto personalizado</li>
                            <li class="grey"><a href="#" class="button box-options-mp" onClick={(e) => { prop.comprar(10, 1799) }}>
                                <img class="imageMP" src="./mercadopago.png" alt="clientes"></img>

                                <Typography className={'text'} component="h2" >
                                {t("comprar")}
                                </Typography>
                            </a>
                            </li>                        </ul>
                    </div>
                </DialogContent>
            </Dialog>

        </div>
    );
}