import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "es",
    fallbackLng: "es",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                title: 'My House AI',
                title_form: 'Make your renders',
                imagen: 'Image',
                habitacion: 'Room Type',
                estilo: 'Style',
                seleccionar: 'Select',
                limpiar: 'Clear',
                enviar: 'Process',
                image_edit: 'Create your image',
                home: 'Home',
                historico: 'History',
                home_titulo: 'Design your space',
                home_cotitulo: 'AI is an excellent tool for generating interior designs and ideas. It can give you an idea of how a space in your home can look and feel',
                uploader: 'Click to add room',
                uploader_sugerencia: 'We suggest 500px 300px',
                nueva_habitacion: 'New room',
                opciones: 'Generate more options',
                drop: 'Drop here',
                standard: 'Standard',
                living: 'Living Room',
                bed: 'Bedroom',
                kitchen: 'Kitchen',
                dining: 'Dining Room',
                bathroom: 'Bathroom',
                home_office: 'Home Office',
                outdoor: 'Outdoor',
                kids_room: 'Kids Room',
                modern: 'Modern',
                scandinavian: 'Scandinavian',
                industrial: 'Industrial',
                midcentury: 'Mid-century',
                luxury: 'Luxury',
                farmhouse: 'Farmhouse',
                coastal: 'Coastal',
                home_title: 'Decorate and furnish your home in ',
                home_title_second: 'seconds.',
                home_text_first: 'Customize each room with your favorite style',
                home_text_second: 'Turn empty rooms into lively spaces',
                home_text_third: 'Immediate results with just a few clicks',
                home_card_first: 'Generate more leads',
                home_card_second: 'Reduce costs/time',
                home_card_third: 'Increase interest',
                home_card_fourth: 'Increase offers',
                volver: 'Recreate',
                volver_historico: 'Return to Historical',
                tooltip: "This field is required",
                generar: "Generate",
                variation_title: "Generate variation",
                variation_text: "Please select the style you want to apply to the new variation.",
                gratis: '(Free)',
                comprar: 'Buy'
            }
        },
        es: {
            translation: {
                title: 'My House AI',
                title_form: 'Creá tu imagen',
                imagen: 'Imagen',
                habitacion: 'Tipo Habitación',
                estilo: 'Estilo',
                seleccionar: 'Seleccionar',
                limpiar: 'Limpiar',
                enviar: 'Procesar',
                image_edit: 'Creá tu imagen',
                home: 'Inicio',
                historico: 'Histórico',
                home_titulo: 'Diseña tu espacio',
                home_cotitulo: 'IA es una excelente herramienta para generar diseños de interiores e ideas. Puede darte una idea de cómo puede lucir y sentirse un espacio de tu casa',
                uploader: 'Hacé click para agregar habitación',
                uploader_sugerencia: 'Sugerimos 500px 300px',
                nueva_habitacion: 'Nueva habitación',
                opciones: 'Generar más opciones',
                drop: 'Arrastrar aquí',
                standard: 'Estandar',
                living: 'Living',
                bed: 'Habitación',
                kitchen: 'Cocina',
                dining: 'Comedor',
                bathroom: 'Baño',
                home_office: 'Oficina',
                outdoor: 'Exterior',
                kids_room: 'Cuarto de Niños',
                modern: 'Moderno',
                scandinavian: 'Escandinavo',
                industrial: 'Industrial',
                midcentury: 'Antiguo',
                luxury: 'Lujoso',
                farmhouse: 'Campo',
                coastal: 'Costero',
                home_title: 'Decorá y amueblá tu casa en ',
                home_title_second: 'segundos.',
                home_text_first: 'Personalizá cada habitación con tu estilo favorito',
                home_text_second: 'Convertí habitaciones vacías en espacios llenos de vida',
                home_text_third: 'Resultados inmediatos y en pocos clicks',
                home_card_first: 'Generá más clientes',
                home_card_second: 'Reduce costos/tiempo',
                home_card_third: 'Aumentá el interés',
                home_card_fourth: 'Incrementá ofertas',
                volver: 'Volver a crear',
                volver_historico: 'Volver a historico',
                tooltip: 'Este campo es requerido',
                generar: 'Generar',
                variation_title: 'Generar variación',
                variation_text: 'Por favor seleccione el estilo que desea aplicar sobre la nueva variación.',
                gratis: '(Gratis)',
                comprar: 'Comprar'
            }
        },
        pt: {
            translation: {
                title: 'My House AI',
                title_form: 'Faça seus renders',
                imagen: 'Imagem',
                habitacion: 'Tipo de Quarto',
                estilo: 'Estilo',
                seleccionar: 'Selecionar',
                limpiar: 'Limpar',
                enviar: 'Processar',
                image_edit: 'Crie sua imagem',
                home: 'Início',
                historico: 'Histórico',
                home_titulo: 'Desenhe seu espaço',
                home_cotitulo: 'IA é uma excelente ferramenta para gerar designs de interiores e ideias. Pode te dar uma ideia de como um espaço da sua casa pode parecer e se sentir',
                uploader: 'Clique para adicionar quarto',
                uploader_sugerencia: 'Sugerimos 500px 300px',
                nueva_habitacion: 'Nova sala',
                opciones: 'Gerar mais opções',
                drop: 'Solta aqui',
                standard: 'Padrão',
                living: 'Sala de Estar',
                bed: 'Quarto',
                kitchen: 'Cozinha',
                dining: 'Sala de Jantar',
                bathroom: 'Banheiro',
                home_office: 'Escritório',
                outdoor: 'Área Externa',
                kids_room: 'Quarto Infantil',
                modern: 'Moderno',
                scandinavian: 'Escandinavo',
                industrial: 'Industrial',
                midcentury: 'Meados do Século',
                luxury: 'Luxuoso',
                farmhouse: 'Fazenda',
                coastal: 'Costeiro',
                home_title: 'Decore e mobílie sua casa em ',
                home_title_second: 'segundos.',
                home_text_first: 'Personalize cada cômodo com seu estilo favorito',
                home_text_second: 'Transforme cômodos vazios em espaços cheios de vida',
                home_text_third: 'Resultados imediatos com apenas alguns cliques',
                home_card_first: 'Gere mais clientes',
                home_card_second: 'Reduza custos/tempo',
                home_card_third: 'Aumente o interesse',
                home_card_fourth: 'Aumente as ofertas',
                volver: 'Recriar',
                volver_historico: 'Voltar ao Histórico',
                tooltip: "Este campo é obrigatório",
                generar: "Gerar",
                variation_title: "Gerar variação",
                variation_text: "Por favor, selecione o estilo que deseja aplicar na nova variação.",
                gratis: '(Grátis)',
                comprar: 'Comprar'
            }
        },
    }
});
export default i18n;