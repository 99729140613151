import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  IconButton,
  Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const ErrorDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '0 8px 32px rgba(234, 124, 105, 0.2)',
  },
}));

const ErrorDialogHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#EA7C69',
  color: theme.palette.common.white,
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ErrorDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#252836',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function ErrorModal({ open, onClose, message }) {
  return (
    <ErrorDialog
      open={open}
      onClose={onClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      fullWidth={true}
      maxWidth={'xs'}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
    >
      <ErrorDialogHeader>
        <Box display="flex" alignItems="center">
          <ErrorOutlineIcon sx={{ fontSize: 28, mr: 2 }} />
          <Typography variant="h5" fontWeight="bold">Error</Typography>
        </Box>
        <CloseButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </CloseButton>
      </ErrorDialogHeader>
      <ErrorDialogContent>
        <Typography variant="body1" sx={{ mb: 3, color: 'white', lineHeight: 1.6, fontSize: '18px' }}>
          {message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            variant="contained" 
            onClick={onClose} 
            sx={{ 
              color: 'white',
              backgroundColor: '#EA7C69', 
              '&:hover': { 
                backgroundColor: '#D66A58' 
              },
              paddingX: 4,
              paddingY: 1,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 'bold'
            }}
          >
            Entendido
          </Button>
        </Box>
      </ErrorDialogContent>
    </ErrorDialog>
  );
}

export default ErrorModal;