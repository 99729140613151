import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import './modal.css';
import MercadoPago from "../MercadoPago/MercadoPago";
import { useState, useEffect } from 'react'
import { Typography, Box, Grid } from '@mui/material'
import TagManager from "react-gtm-module";

const style = {
    background: '#252836',
    padding: '25px'
};

export default function ModalMercadoPago(prop) {
    return (
        <Dialog
            fullWidth={true}
            open={prop.open}
            onClose={prop.handleModalClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth={'xs'}
            PaperProps={{ sx: { borderRadius: "10px 10px 10px 10px", padding: '0px' } }}
        >
            <DialogContent style={style}>
                <Box >
                    <Typography variant="h6" sx={{paddingBottom: '20px'}}>
                        Confirmación de compra
                    </Typography>
                </Box>
                <MercadoPago setIsLoading={prop.setIsLoading} handleClose={prop.handleModalClose} parameters={prop.parameters} imagenes={prop.imagenes}></MercadoPago>
            </DialogContent>
        </Dialog >
    );
}