import { Typography, Box, Grid } from '@mui/material'
import { BeforeAfter } from '../beforeAfter/beforeAfter';
import { Carousel } from "react-responsive-carousel";
import { Container } from '@mui/material';
import { useState, useEffect } from 'react'
import Historico from '../Historico/historico'
import LoadingSpinner from "../Loading/LoadingSpinner";
import { useTranslation } from 'react-i18next';

function Home(prop) {
  const [menuActive, setMenuActive] = useState("home")
  const [historial, setHistorial] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [imageOutputVariation, setImageOutputVariation] = useState();
  const { i18n, t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleHistorico = () => {
    setIsLoading(true)
    fetch(apiUrl + '/api/historial', {
      method: 'get',
      headers: {
        'token': 'Bearer ' + localStorage.getItem('userToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        setImageOutputVariation([])
        setHistorial(data);
        setMenuActive('historico');
        setIsLoading(false)

      })
      .catch(error => {
        console.error('Error:', error)
        setIsLoading(false)
      });

  }

  const syncWait = (ms) => {
    const end = Date.now() + ms
    while (Date.now() < end) continue
  }

  const callVariacionHistorico = (formData, renderId) => {
    prop.setLoadingValue(10)
    handleVariacion(formData, 0, renderId)
  }

  const handleVariacion = (formData, variacion, render_id) => {
    fetch(apiUrl + '/api/generar', {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      },
      body: JSON.stringify({
        "generation_id": render_id,
        "style": formData.estilo,
        "roomType": formData.tipo
      })
    })
      .then(response => response.json())
      .then(data => {
        if (variacion === 0) {
          prop.setLoadingValue(17)
        }
        obtenerRespuesta(data.generation_id, formData, variacion, render_id);
      })
      .catch(error => {
        console.error('Error:', error)
        prop.setLoadingValue(0)
      });
  }
  const handlerLoading = (value) => {
    setIsLoading(value)
  }
  const obtenerRespuesta = (generation_id, formData, variacion, render_id) => {
    fetch(apiUrl + '/api/status/' + generation_id, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        // 'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == 'rendering' || data.status == 'queued') {
          if (data.progress === 0 && variacion === 0) {
            prop.setLoadingValue(loadingValue => {
              const nextValue = loadingValue + randomIncrement(50, loadingValue);
              return nextValue;
            });
          } else if (data.progress !== 0 && variacion === 0) {
            prop.setLoadingValue(prevValue => {
              const nextValue = prevValue + randomIncrement(100, prevValue);
              return nextValue;
            });
          }

          syncWait(500)
          obtenerRespuesta(generation_id, formData, variacion, render_id);
          return;
        }
        if (data.status == 'error') {
          handleVariacion(generation_id, formData, variacion)
        }
        prop.setLoadingValue(0)
        if (variacion < 1) {
          data.outputs = data.outputs.reverse()
          setImageOutputVariation(data.outputs)
          syncWait(500)

          handleVariacion(formData, variacion + 1, render_id)
        } else {
          data.outputs = data.outputs.reverse()
          setImageOutputVariation(data.outputs)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      });

    function randomIncrement(target, currentValue) {
      const maxLimit = target === 50 ? 49.9 : 99.9; // Limites de falso incremento
      const maxIncrement = maxLimit - currentValue; // Máximo que puede incrementar
      const increment = Math.random() * (maxIncrement / 5); // Genera un valor aleatorio

      return increment > maxIncrement ? maxIncrement : increment; // Controla el límite superior
    }

  }
  return (
    <div style={{ height: '100%' }}>
      <Container maxWidth={false} sx={{
        borderBottom: '1px solid #3a3845', height: '60px', background: '#1F1D2B', paddingTop: '29px!important',
        paddingLeft: '0px!important'
      }}>
        <Box sx={{ display: 'flex' }}>
          {/* <TuneIcon></TuneIcon> */}
          <Typography className={"menu " + (menuActive == 'home' ? 'menuActive' : 'hidden')} component="span" onClick={() => setMenuActive('home')}
            sx={{ fontFamily: 'Barlow', fontWeight: '600', fontSize: '14px' }}>{t("home")}</Typography>
          {localStorage.getItem('userToken') &&
            <Typography className={"menu " + (menuActive == 'historico' ? 'menuActive' : 'hidden')} component="span" onClick={() => handleHistorico()}
              sx={{ marginLeft: '10px', fontFamily: 'Barlow', fontWeight: '600', fontSize: '14px' }}>{t("historico")}</Typography>
          }
        </Box>
      </Container>
      {menuActive == 'home' ?
        <Container sx={{
          alignItems: 'flex-start',
          '@media (max-width: 1000px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
          }
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between', // Distribuye los Box de manera uniforme
            // alignItems: 'center',
            alignItems: 'flex-start' // Opcional: centra los Box verticalmente
          }}>
            <Box sx={{ width: '38%', alignSelf: 'center' }}>
              <Box sx={{ justifyContent: 'center' }}>
                <Typography variant="h1" component="h1" sx={{
                  marginBottom: '30px', fontFamily: 'Barlow', fontWeight: '700', fontSize: '30px', textAlign: 'left',
                  '@media (max-width: 1250px)': {
                    fontSize: '26px',
                  },
                  '@media (max-width: 1150px)': {
                    fontSize: '22px',
                    marginBottom: '0px',
                  },
                  '@media (max-width: 1080px)': {
                    fontSize: '20px',
                    marginTop: '15px'
                  }
                }}>
                  {t("home_title")}<Box sx={{ color: '#ea7c69', display: 'inline-block' }}>{t("home_title_second")}</Box>
                </Typography>
              </Box>
              <Box sx={{ justifyContent: 'center' }}>
                <ul style={{ paddingInlineStart: "15px" }}>
                  <li>
                    <Typography variant="h2" component="h2" align="left" className={"home-texto"} sx={{
                      marginTop: '15px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px',
                      '@media (min-width: 1250px)': {
                        fontSize: '19px'
                      },
                      '@media (max-width: 1000px)': {
                        fontSize: '14px'
                      }
                    }}>
                      {t("home_text_first")}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h2" component="h2" align="left" sx={{
                      marginTop: '15px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px',
                      '@media (min-width: 1250px)': {
                        fontSize: '19px'
                      },
                      '@media (max-width: 1000px)': {
                        fontSize: '14px'
                      }
                    }}>
                      {t("home_text_second")}
                    </Typography>
                  </li>
                  <li>
                    <Typography component="h2" variant="h2" align="left" sx={{
                      marginTop: '15px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px',
                      '@media (min-width: 1250px)': {
                        fontSize: '19px'
                      },
                      '@media (max-width: 1000px)': {
                        fontSize: '14px'
                      }
                    }}>
                      {t("home_text_third")}
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
            <Box sx={{
              justifyContent: 'center', display: 'flex', width: '60%',
              '@media (max-width: 1400px)': {
                width: '70%'
              }
            }}>
              <Carousel sx={{
                width: '100%', '@media (max-width: 1400px)': {
                  width: '65%'
                }
              }} className='homeCarousel' showStatus={false} showThumbs={false}>
                <BeforeAfter
                  // pointerMove
                  beforeImage="./5_antes.jpg"
                  afterImage="./5_despues.jpg"
                />
                <BeforeAfter
                  // pointerMove
                  beforeImage="./2_antes.jpg"
                  afterImage="./2_despues.png"
                />
                <BeforeAfter
                  // pointerMove
                  beforeImage="./1_antes.jpg"
                  afterImage="./1_despues.png"
                />
                <BeforeAfter
                  // pointerMove
                  beforeImage="./3_antes.jpg"
                  afterImage="./3_despues.jpg"
                />
                <BeforeAfter
                  // pointerMove
                  beforeImage="./4_antes.jpg"
                  afterImage="./4_despues.png"
                />
                <BeforeAfter
                  // pointerMove
                  beforeImage="./ejemplo_1_antes.jpg"
                  afterImage="./ejemplo_1_despues.jpg"
                />
                <BeforeAfter
                  // pointerMove
                  beforeImage="./ejemplo_5_antes.jpg"
                  afterImage="./ejemplo_5_despues.jpg"
                />
              </Carousel>
            </Box>
          </Box>

          <Grid container className='options' sx={{}}>
            <Grid item sm={6} md={6} lg={3} className={'box-options'}>
              <Box sx={{ display: 'flex' }}>
                {/* <TrendingUpIcon sx={{ alignSelf: 'center' , display: 'flex'}}></TrendingUpIcon> */}
                {/* <img src="./clientes_white.png"></img> */}
                <img src="./clientes.svg" alt="clientes"></img>
              </Box>
              <Typography className={'text'} component="h3" >
                {t("home_card_first")}
              </Typography>
            </Grid>
            <Grid item sm={6} md={6} lg={3} className={'box-options'}>
              <Box sx={{ display: 'flex' }}>
                {/* <TrendingDownIcon sx={{ alignSelf: 'center' , display: 'flex'}}></TrendingDownIcon> */}
                <img src="./reducir-el-costo.svg" alt="costo"></img>
              </Box>
              <Typography className={'text'} component="h3" >
                {t("home_card_second")}
              </Typography>
            </Grid>
            <Grid item sm={6} md={6} lg={3} className={'box-options'}>
              <Box sx={{ display: 'flex' }}>
                {/* <CheckIcon sx={{ alignSelf: 'center', display: 'flex' }}></CheckIcon> */}
                <img src="./incrementar_interes.svg" alt="interes"></img>
              </Box>
              <Typography className={'text'} component="h3" >
                {t("home_card_third")}
              </Typography>
            </Grid>
            <Grid item sm={6} md={6} lg={3} className={'box-options'}>
              <Box sx={{ display: 'flex' }}>
                {/* <ArrowUpwardIcon sx={{ alignSelf: 'center' , display: 'flex'}}></ArrowUpwardIcon> */}
                <img src="./incrementar_cliente.svg" alt="incrementar"></img>
              </Box>
              <Typography className={'text'} component="h3" >
                {t("home_card_fourth")}
              </Typography>
            </Grid>
          </Grid>

        </Container >
        :
        <div className={'historico'}>
          <Historico historial={historial} handleVariacion={callVariacionHistorico} imageOutputVariation={imageOutputVariation} handlerLoading={handlerLoading} />
        </div>
      }
      {
        isLoading && (
          <div >
            <LoadingSpinner />
          </div>
        )
      }
    </div >
  )
}

export default Home