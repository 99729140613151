import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from '../../styles/Loading.module.css'

export default function LoadingProgress(props) {
  return (
    <Box className={styles.spinnerContainer}  sx={{ position: 'relative', display: 'inline-flex', zIndex: 10000 }}>
      <CircularProgress variant="determinate" {...props} sx={{color:'#ea7c69'}} size={60} thickness={6}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" sx={{fontWeight: '600', fontFamily: 'Barlow', fontSize: '0.9rem'}}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}