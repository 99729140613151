import { useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, ThemeProvider, createTheme, Typography } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import HomeIcon from '@mui/icons-material/Home';
import Uploader from '../Uploader/uploader'
import LoadingSpinner from "../Loading/LoadingSpinner";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const style = {
  marginBottom: '2px',
  "& .MuiOutlinedInput-notchedOutline": {
    color: '#9a8c98',
    border: '#9a8c98 solid 1px'
  },
  "& .Mui-focused.MuiOutlinedInput-root": {
    color: '#f2e9e4',
  },
  "& label.Mui-focused": {
    color: "#f2e9e4"
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#f2e9e4"
    }
  }
}

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    label: {
      primary: '#fff',
      secondary: '#fff',
    },
    text: {
      primary: '#f2e9e4',
      secondary: '#f2e9e4',
    }
  },
});


function Form(prop) {
  const { i18n, t } = useTranslation();
  // const [filteredEstilo, setFilteredEstilo] = useState([
  //   { name: 'Moderno', value: 'MODERN', room: ['LIVING_ROOM', 'BEDROOM', 'DINING_ROOM', 'KITCHEN', 'HOME_OFFICE'] },
  //   { name: 'Escandinavo', value: 'SCANDINAVIAN', room: ['LIVING_ROOM', 'BEDROOM', 'DINING_ROOM', 'KITCHEN', 'HOME_OFFICE'] },
  //   { name: 'Lujoso', value: 'LUXURIOUS', room: ['LIVING_ROOM', 'BEDROOM', 'KITCHEN'] },
  //   { name: 'Chic/Campo', value: 'FARMHOUSE_CHIC', room: ['LIVING_ROOM', 'BEDROOM'] },
  //   { name: 'Industrial', value: 'INDUSTRIAL', room: ['BEDROOM'] },
  // ])
  const [isLoading, setIsLoading] = useState(false);
  const [estilo, setEstilo] = useState([]);
  const [cleanImage, setCleanImage] = useState(false);
  const [checked, setChecked] = useState(false);

  const [errors, setErrors] = useState({
    estilo: false,
    tipo: false,
    image: false,
  });

  const [formData, setFormData] = useState({
    estilo: '',
    tipo: '',
  });
  const [image, setImage] = useState(null)

  const handleEstilo = (event) => {
    // setEstilo(filteredEstilo.filter(
    //   (item) => item.room.includes(event.target.value)
    // ));
  }

  useEffect(() => {
    setFormData({
      estilo: '',
      tipo: '',
      numImagenes: '',
    })
    setCleanImage(prop.cleanForm)
  }, [prop.cleanForm]);

  const handleChange = (event) => {
    let formErrors = { ...errors };;
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name == 'tipo')
      formErrors.tipo = !value ? true : false;
    if (name == 'estilo')
      formErrors.estilo = !value ? true : false;

    setErrors(formErrors);

  };

  const handleSubmit = (event) => {

    event.preventDefault();
    let formErrors = { ...errors };;

    formErrors.tipo = !formData.tipo ? true : false;
    formErrors.estilo = !formData.estilo ? true : false;
    formErrors.image = !image ? true : false;
    setErrors(formErrors);

    if (!formErrors.tipo &&
      !formErrors.estilo &&
      !formErrors.image) {
      prop.submitForm(formData, checked, image);
    }
    // return
  };

  const onChangeImage = (files) => {
    if (files) {
      var reader = new FileReader();
      reader.readAsBinaryString(files);
      reader.onload = () => {
        // setBase64(btoa(reader.result))
        setImage(btoa(reader.result))
      }
    }
    //Seteo error en false siempre, solo se muestra al apretar boton submit.
    let formErrors = { ...errors };
    formErrors.image = false;
    setErrors(formErrors);
  }

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

  };

  return (
    <ThemeProvider theme={theme}>
      <Uploader onChangeImage={onChangeImage} cleanImage={cleanImage} />
      <span className={"error-message " + (errors.image ? 'show' : 'hide')}>{t("tooltip")}</span>

      <form onSubmit={handleSubmit} sx={{ marginBottom: '15px', marginTop: '20px' }}>
        <Box sx={{ marginBottom: '15px', marginTop: '20px', '@media (max-width: 1440px)': { marginBottom: '5px', marginTop: '10px' } }}>
          <FormControl fullWidth variant="outlined" margin="normal" sx={style}>
            <InputLabel htmlFor="tipo" className="label">
              <Box sx={{ display: 'flex' }}>
                <HomeIcon sx={{ color: '#C4C4C4' }}></HomeIcon>
                <Typography sx={{ paddingLeft: '10px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px', color: '#C4C4C4' }}>{t("habitacion")}</Typography>
              </Box>
            </InputLabel>
            <Select
              label="Tipo Habitacion               ab"
              id="tipo"
              name="tipo"
              value={formData.tipo}
              onChange={(e) => { handleEstilo(e); handleChange(e); }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "#9a8c98"
                  },
                  "&& .MuiPaper-root": {
                    backgroundColor: "#4a4e69"
                  }
                }
              }}
            >
              <MenuItem value="">{t("seleccionar")}</MenuItem>
              <MenuItem value="living">{t("living")}</MenuItem>
              <MenuItem value="bed">{t("bed")}</MenuItem>
              <MenuItem value="kitchen">{t("kitchen")}</MenuItem>
              <MenuItem value="dining">{t("dining")}</MenuItem>
              <MenuItem value="bathroom">{t("bathroom")}</MenuItem>
              <MenuItem value="home_office">{t("home_office")}</MenuItem>
              <MenuItem value="outdoor">{t("outdoor")}</MenuItem>
              <MenuItem value="kids_room">{t("kids_room")}</MenuItem>
            </Select>
          </FormControl>
          <span className={"error-message " + (errors.tipo ? 'show' : 'hide')}>{t("tooltip")}</span>
        </Box>
        <Box sx={{}}>
          <FormControl fullWidth variant="outlined" margin="normal" sx={style}>
            <InputLabel htmlFor="estilo" className="label">
              <Box sx={{ display: 'flex' }}>
                <ImageSearchIcon sx={{ color: '#C4C4C4' }}></ImageSearchIcon>
                <Typography sx={{ paddingLeft: '10px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px', color: '#C4C4C4' }}>{t("estilo")}</Typography>
              </Box>
            </InputLabel>
            <Select
              label="Estilo                                                   abb"
              id="estilo"
              name="estilo"
              value={formData.estilo}
              onChange={(e) => { handleChange(e) }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "#9a8c98"
                  },
                  "&& .MuiPaper-root": {
                    backgroundColor: "#4a4e69",
                    fontFamily: 'Barlow'
                  }
                }
              }}
            >
              {/* {estilo.map((c) => (
                <MenuItem value={c.value}>{c.name}</MenuItem>
              ))} */}
              <MenuItem value="">{t("seleccionar")}</MenuItem>
              <MenuItem value="standard">{t("standard")}</MenuItem>
              <MenuItem value="industrial">{t("industrial")}</MenuItem>
              <MenuItem value="scandinavian">{t("scandinavian")}</MenuItem>
              <MenuItem value="modern">{t("modern")}</MenuItem>
              <MenuItem value="farmhouse">{t("farmhouse")}</MenuItem>
              <MenuItem value="midcentury">{t("midcentury")}</MenuItem>
              <MenuItem value="coastal">{t("coastal")}</MenuItem>
              <MenuItem value="luxury">{t("luxury")}</MenuItem>
            </Select>
          </FormControl>
          <span className={"error-message " + (errors.estilo ? 'show' : 'hide')}>{t("tooltip")}</span>
        </Box>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangeCheck}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  color: '#EA7C69!important',
                  padding: '20px',
                  marginRight: '-20px', // Esto compensa el padding del checkbox
                }}
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Remover muebles/elementos
                <Tooltip
                  title="Use esta opción si la habitación tiene muebles o elementos que desea eliminar antes de generar la imagen. Tener en cuenta que esto puede aumentar la demora en la generación."
                  sx={{
                    fontSize: '1.1rem', // Aumenta el tamaño de la fuente
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontFamily:'Barlow',
                        fontSize: '1rem', // Tamaño de la fuente del contenido del tooltip
                        padding: '8px 12px', // Ajuste de padding para el texto más grande
                        backgroundColor: '#333', // Opcional: cambio de color de fondo
                      },
                    },
                  }}
                >
                  <IconButton sx={{ marginLeft: '8px', padding: '0' }}>
                    <HelpOutlineIcon sx={{ color: '#C4C4C4' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            labelPlacement="start"
            sx={{
              margin: 0,
              width: '100%',
              justifyContent: 'space-between',
              '.MuiFormControlLabel-label': {
                flex: 1,
                color: '#C4C4C4',
                fontFamily: 'Barlow',
                fontWeight: '500',
                fontSize: '16px',
              },
            }}
          />
        </Box>



        <Box sx={{
          display: 'grid', marginTop: '20px',
          '@media (min-width: 1440px)': {
            marginTop: '31px'
          }
        }}>
          <button className="btnEnviar" onClick={(e) => {
            // alert("asd")
          }}>
            <Box sx={{ display: 'grid' }}>
              <Typography sx={{ fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white' }}>{t("enviar")}</Typography>
            </Box>
          </button>
        </Box>
      </form>
      {isLoading && (
        <div >
          <LoadingSpinner />
        </div>
      )}
    </ThemeProvider>
  );
};
export default Form