import { Wallet } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react'
import LoadingSpinner from "../Loading/LoadingSpinner";
import TagManager from "react-gtm-module";

export default function MercadoPago(prop) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [parameters, setParameters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const handleSubmit = () => {
    return new Promise((resolve, reject) => {
      let data = JSON.parse(prop.imagenes)
      fetch(apiUrl + '/api/payment/create_preference', {
        method: 'POST',
        headers: {
          'token': 'Bearer ' + localStorage.getItem('userToken')
        },
        body: JSON.stringify({
          "quantity": data.cantidad,
          "unit-price": data.precio
        })
      })
        .then((response) => response.json())
        .then((data) => {
          // resolver a promise com o ID da preferência
          resolve(data.id);
        })
        .catch((error) => {
          // lidar com a resposta de erro ao tentar criar a preferência
          reject();
        });
    });
  }

  const handleOnReady = (data) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/comprar/mercadopago',
        pageTitle: 'Boton de mercado pago previo a compra',
      },
    });
    prop.setIsLoading(false);
  }

  const handleError = (data) => {
    console.log(data)
  }

  return (
    <Box className="wallet">
      {(show) && (<Wallet
        initialization={{ preferenceId: prop.preferenceId }}
        onSubmit={handleSubmit}
        onReady={handleOnReady}
        onError={handleError}
      // customization={{ visual: { buttonBackground: 'black', borderRadius: '20px' } }}/>
      />
      )}
      {loading && (
        <div >
          <LoadingSpinner />
        </div>
      )}
    </Box>
  );
};